import React, { useEffect, useState } from "react";
import axios from "axios";

import Tile from "./components/Tile";
import Footer from "./components/Footer";
import LiveIndicator from "./components/LiveIndicator";

const infos = [
  {
    hideButton: true,
    minheight: "100vh",
    logo: "./images/preview/AVLOGOfff.png",
    background: "./images/backgrounds/W6.png",
    title: "Remember to visualize before audio!",
    showLive: false,
  },
  {
    logo: "./images/preview/Visuals-512x512.png",
    background: "./images/backgrounds/W7.png",
    title: "Visuals",
    description: "Watch videos together. Anywhere. Whenever.",
    link: "https://visuals.audiovisuaali.net/",
    showLive: false,
  },
  {
    logo: "./images/preview/popcorn.png",
    background: "./images/backgrounds/W4.png",
    title: "Movie night",
    description:
      "Here you can enjoy a movie together. Sometimes extra games are streamed here!",
    link: "https://live.audiovisuaali.net/",
    showLive: true,
  },
  {
    logo: "./images/preview/Simply-Styled-TeamSpeak.ico",
    background: "./images/backgrounds/W1.png",
    title: "Teamspeak",
    description:
      "We offer teamspeak VoIP. Here YOU are permitted to create your own room! We also have a lot of icons varying from country flags to too many useless emotes! We also offer ranks which you can acquire by talking on the server or setting them from the website!",
    link: "https://audiovisuaali.net/teamspeak/ranksystem/stats/",
    showLive: false,
  },
  {
    logo: "./images/preview/whitedust.png",
    background: "./images/backgrounds/W3.png",
    title: "WHITEDUST",
    description:
      "Whitedust is for sharing computer builds. Whitedust makes it easy and effortless to share all the information about a product or/and a build.",
    link: "https://whitedust.audiovisuaali.net/",
    showLive: false,
  },
  {
    logo: "./images/preview/kjeh.png",
    background: "./images/backgrounds/W2.png",
    title: "KJEH",
    description:
      "With ShareX and Kjeh you can instantly take screenshot and share them with your friends!",
    link: "https://share.audiovisuaali.net/",
    showLive: false,
  },
];

const liveCheckURL = "https://live.audiovisuaali.net/hls/stream.m3u8";
const liveSiteURL = "https://live.audiovisuaali.net/";

const App = () => {
  const [isLive, setIsLive] = useState(false);

  const checkForLive = async () => {
    try {
      await axios.get(liveCheckURL);
      setIsLive(true);
    } catch (e) {
      setIsLive(false);
    }
  };

  useEffect(() => {
    checkForLive();
    const interval = setInterval(checkForLive, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex-box no-select">
      <LiveIndicator isLive={isLive} to={liveSiteURL} />
      {infos.map((info, index) => (
        <Tile key={index} isLive={isLive} {...info} />
      ))}
      <Footer />
    </div>
  );
};

export default App;
