import React from "react";
import "./LiveIndicator.css";

export const PulsingBall = () => (
  <span className="live-red-ball-outer">
    <div className="live-red-ball-inner"></div>
  </span>
);

const LiveIndicator = ({ to, isLive }) => (
  <div className="live-container">
    <div
      className={isLive ? "live-show" : "live-hide"}
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "0 auto",
        display: "flex",
        transition: "all 0.5s",
      }}
    >
      <a href={to} alt="Audiovisuaali - Live" className="live-pill">
        <PulsingBall />
        LIVE
      </a>
    </div>
  </div>
);

export default LiveIndicator;
